<template>
  <div v-if="$store.state.settings.init" class="app-settings">
    <div v-if="this.selectedRole" class="admin-popup">
      <span class="material-icons close-icon" @click="exitRoleEdit">close</span>
      <h3>
        Change role name for <b>{{ selectedRole.slug }}</b>
      </h3>
      <input
        v-model="newRoleName"
        ref="roleName"
        type="text"
        class="input"
        placeholder="Enter new role name"
      />
      <button @click="saveRole">Save</button>
    </div>

    <div v-if="this.selectedOption" class="admin-popup">
      <span class="material-icons close-icon" @click="exitOptionEdit"
        >close</span
      >
      <h3>
        Editing field: <b>{{ selectedOption }}</b>
      </h3>
      <input
        v-model="optionField"
        ref="optionField"
        type="text"
        class="input"
        placeholder="Enter new value..."
      />
      <button @click="saveOption">Save</button>
    </div>

    <div v-if="this.isCustomerPriceGroupOpen" class="admin-popup">
      <span class="material-icons close-icon" @click="closeCustomerPriceGroup"
        >close</span
      >
      <h3>
        Editing field:
        <b>{{
          customerPriceGroupField.charAt(0).toUpperCase() +
          customerPriceGroupField.slice(1)
        }}</b>
      </h3>
      <input
        v-model="customerPriceGroupId"
        type="text"
        class="input"
        placeholder="ID"
        :class="{ disabled: customerPriceGroupMode === 'edit' }"
      />
      <input
        v-model="customerPriceGroupName"
        type="text"
        class="input"
        placeholder="Name"
      />
      <button @click="savePriceGroup">Save</button>
    </div>
    <div v-if="this.isEditingPriceGroupName" class="admin-popup">
      <span class="material-icons close-icon" @click="closeGroupNameEdit"
        >close</span
      >
      <h3>
        Edit name for:
        <b>{{ selectedPriceGroup }}</b>
      </h3>
      <input
        v-model="newPriceGroupName"
        type="text"
        class="input"
        placeholder="new name"
      />

      <button @click="savePriceGroupName">Save</button>
    </div>

    <h1>App settings</h1>

    <div class="user-roles">
      <h3>User roles</h3>
      <div class="admin-table user-roles-table">
        <div class="table-header">
          <span class="id label">ID</span>
          <span class="name label">Name</span>
        </div>

        <div v-for="role in userRolesSorted" :key="role.slug">
          <span class="id">{{ role.slug }}</span>
          <span class="name">
            {{ role.name }}
            <span
              class="material-icons edit-icon"
              @click="initRoleEdit(role)"
              v-if="userRole.slug === 'superAdmin'"
              >edit</span
            >
          </span>
        </div>
      </div>

      <h3>Options</h3>
      <div class="admin-table horizontal options-table">
        <div>
          <span class="key"> App </span>
          <span class="value">
            {{ appSettings.app }}
          </span>
        </div>

        <div>
          <span class="key"> Environment </span>
          <span class="value">
            {{ appSettings.env }}
          </span>
        </div>

        <div>
          <span class="key"> Contact number </span>

          <span class="value">
            {{ appSettings.phoneNumber }}

            <span
              class="material-icons edit-icon"
              @click="initOptionEdit('phoneNumber')"
              v-if="userRole.slug === 'superAdmin' || userRole.slug === 'admin'"
              >edit</span
            >
          </span>
        </div>
        <div>
          <span class="key"> Visma REST api </span>

          <span class="value">
            {{ appSettings.vismaRest }}

            <span
              class="material-icons edit-icon"
              @click="initOptionEdit('vismaRest')"
              v-if="userRole.slug === 'superAdmin'"
              >edit</span
            >
          </span>
        </div>

        <div>
          <span class="key"> Cloud functions </span>

          <span class="value">
            {{ appSettings.cloudFunctions }}
            <span
              class="material-icons edit-icon"
              @click="initOptionEdit('cloudFunctions')"
              v-if="userRole.slug === 'superAdmin'"
              >edit</span
            >
          </span>
        </div>
      </div>
    </div>

    <NoCreditMessage v-if="userRole.slug == 'superAdmin'" />
    <Maintenance />
    <CurrencySettings
      v-if="appBrand === 'kvalitetsfisk' || appBrand === 'fsg'"
    />
    <DeliveryDateSettings />
    <ProductImageToggle />
    <ProductCategories />
    <ExtraProductCategories />
    <h3>Categories</h3>
    <p>Managed manually. Not connected to VismaAPI.</p>
    <div class="admin-table company-categories-table">
      <div class="table-header">
        <span class="id label">ID</span>
        <span class="name label">Name</span>
      </div>

      <div v-for="(value, key) in categories" :key="'company-cat-' + key">
        <span class="id">{{ key }}</span>
        <span class="name">
          {{ value }}
          <span
            class="material-icons edit-icon"
            @click="editCustomerPriceGroup(key, value, 'categories')"
            v-if="userRole.slug === 'superAdmin'"
            >edit</span
          >
          <span
            class="material-icons delete-icon"
            @click="deleteCustomerPriceGroup(key, value, 'categories')"
            v-if="userRole.slug === 'superAdmin'"
            >delete</span
          >
        </span>
      </div>
    </div>
    <button
      class="add-row-button"
      @click="addCustomerPriceGroup('companyCategories')"
    >
      Add company category
    </button>

    <h3>{{ priceGroup1.name }}</h3>
    <p>Managed manually. Not connected to VismaAPI.</p>
    <div class="admin-table company-categories-table">
      <div class="table-header">
        <span class="id label">ID</span>
        <span class="name label">Name</span>
      </div>

      <div
        v-for="(value, key) in priceGroup1"
        :key="'company-cat-' + key"
        :class="{ hidden: isHidden(key) }"
      >
        <div v-if="key != 'name'">
          <span class="id">{{ key }}</span>
          <span class="name">
            {{ value }}
            <span
              class="material-icons edit-icon"
              @click="editCustomerPriceGroup(key, value, priceGroup1.name)"
              v-if="userRole.slug === 'superAdmin'"
              >edit</span
            >
            <span
              class="material-icons delete-icon"
              @click="deleteCustomerPriceGroup(key, value, priceGroup1.name)"
              v-if="userRole.slug === 'superAdmin'"
              >delete</span
            >
          </span>
        </div>
      </div>
    </div>
    <button
      class="add-row-button"
      @click="addCustomerPriceGroup('priceGroup1')"
    >
      Add company price group 1
    </button>
    <button
      class="add-row-button"
      @click="openGroupNameEdit(priceGroup1.name, 'priceGroup1')"
      style="margin-left: 10px"
    >
      Update name of price group
    </button>

    <h3>{{ priceGroup2.name }}</h3>
    <p>Managed manually. Not connected to VismaAPI.</p>
    <div class="admin-table company-categories-table">
      <div class="table-header">
        <span class="id label">ID</span>
        <span class="name label">Name</span>
      </div>

      <div
        v-for="(value, key) in priceGroup2"
        :key="'company-cat-' + key"
        :class="{ hidden: isHidden(key) }"
      >
        <div v-if="key != 'name'">
          <span class="id">{{ key }}</span>
          <span class="name">
            {{ value }}
            <span
              class="material-icons edit-icon"
              @click="editCustomerPriceGroup(key, value, priceGroup2.name)"
              v-if="userRole.slug === 'superAdmin'"
              >edit</span
            >
            <span
              class="material-icons delete-icon"
              @click="deleteCustomerPriceGroup(key, value, priceGroup2.name)"
              v-if="userRole.slug === 'superAdmin'"
              >delete</span
            >
          </span>
        </div>
      </div>
    </div>
    <button
      class="add-row-button"
      @click="addCustomerPriceGroup('priceGroup2')"
    >
      Add company price group 2
    </button>
    <button
      class="add-row-button"
      @click="openGroupNameEdit(priceGroup2.name, 'priceGroup2')"
      style="margin-left: 10px"
    >
      Update name of price group
    </button>

    <h3>{{ priceGroup3.name }}</h3>
    <p>Managed manually. Not connected to VismaAPI.</p>
    <div class="admin-table company-categories-table">
      <div class="table-header">
        <span class="id label">ID</span>
        <span class="name label">Name</span>
      </div>

      <div
        v-for="(value, key) in priceGroup3"
        :key="'company-cat-' + key"
        :class="{ hidden: isHidden(key) }"
      >
        <div v-if="key != 'name'">
          <span class="id">{{ key }}</span>
          <span class="name">
            {{ value }}
            <span
              class="material-icons edit-icon"
              @click="editCustomerPriceGroup(key, value, priceGroup3.name)"
              v-if="userRole.slug === 'superAdmin'"
              >edit</span
            >
            <span
              class="material-icons delete-icon"
              @click="deleteCustomerPriceGroup(key, value, priceGroup3.name)"
              v-if="userRole.slug === 'superAdmin'"
              >delete</span
            >
          </span>
        </div>
      </div>
    </div>

    <button
      class="add-row-button"
      @click="addCustomerPriceGroup('priceGroup3')"
    >
      Add company price group 3
    </button>
    <button
      class="add-row-button"
      @click="openGroupNameEdit(priceGroup3.name, 'priceGroup3')"
      style="margin-left: 10px"
    >
      Update name of price group
    </button>

    <h3>Routes</h3>
    <p>Managed manually. Not connected to VismaAPI.</p>

    <div class="admin-table company-categories-table">
      <div class="table-header">
        <span class="id label">ID</span>
        <span class="name label">Name</span>
      </div>

      <div v-for="(value, key) in companyRoutes" :key="'company-route-' + key">
        <div v-if="key != 'name'">
          <span class="id">{{ key }}</span>
          <span class="name">
            {{ value }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProductCategories from "../components/settings/ProductCategories";
import ExtraProductCategories from "../components/settings/ExtraProductCategories";

export default {
  name: "AppSettings",

  data() {
    return {
      selectedRole: false,
      newRoleName: "",
      selectedOption: false,
      optionField: "",
      newProductCategory: "",
      isCustomerPriceGroupOpen: false,
      isEditingPriceGroupName: false,

      targetPriceGroup: "",
      newPriceGroupName: "",
      selectedPriceGroup: "",
      selectedCategory: "",
      selectedCategoryId: "",
      customerPriceGroupId: "",
      customerPriceGroupName: "",
      customerPriceGroupMode: "edit",
      customerPriceGroupField: "",
      productCategoryId: "",
      productCategory: "",
      unavailableDates: [],
    };
  },
  components: {
    ProductCategories,
    ExtraProductCategories,
  },
  computed: {
    ...mapState({
      userList: (state) => state.users.userList,
      appSettings: (state) => state.settings.appSettings,
      appBrand: (state) => state.settings.appSettings.app,
      appEnv: (state) => state.settings.appSettings.env,
    }),
    ...mapGetters("users", ["getUserBySearch"]),
    ...mapGetters("settings", [
      "userRole",
      "userRoles",
      "userRolesSorted",
      "taxonomies",
      "priceGroup1",
      "priceGroup2",
      "priceGroup3",
      "categories",
      "companyCategories",
      "companyPriceGroups",
      "companyPriceGroups2",
      "companyPriceGroups3",
      "companyRoutes",
    ]),
  },
  methods: {
    isHidden: (key) => (key == "name" ? true : false),
    editCustomerPriceGroup: function (key, value, field) {
      this.customerPriceGroupMode = "edit";
      this.customerPriceGroupId = key;
      this.customerPriceGroupName = value;
      this.customerPriceGroupField = field;
      this.isCustomerPriceGroupOpen = true;
    },
    closeCustomerPriceGroup: function () {
      this.customerPriceGroupMode = "edit";
      this.customerPriceGroupId = "";
      this.customerPriceGroupName = "";
      this.customerPriceGroupField = "";
      this.isCustomerPriceGroupOpen = false;
    },
    addCustomerPriceGroup: function (field) {
      this.customerPriceGroupMode = "add";
      this.customerPriceGroupName = "";
      this.customerPriceGroupId = "";
      this.customerPriceGroupField = field;
      this.isCustomerPriceGroupOpen = true;
    },

    openGroupNameEdit: function (priceGroup, target) {
      this.isEditingPriceGroupName = true;
      this.selectedPriceGroup = priceGroup;
      this.targetPriceGroup = target;
    },
    closeGroupNameEdit: function () {
      this.isEditingPriceGroupName = false;
      this.selectedPriceGroup = "";
      this.targetPriceGroup = "";
    },
    savePriceGroupName: function () {
      if (this.newPriceGroupName === "") {
        return false;
      }

      this.$store
        .dispatch("settings/updatePriceGroupName", {
          target: this.targetPriceGroup,
          newName: this.newPriceGroupName,
        })
        .then(() => {
          this.newPriceGroupName = "";
          this.closeGroupNameEdit();
        });
    },

    deleteCustomerPriceGroup: function (key, value, field) {
      let confirm = window.confirm(
        "About to permanently delete field " +
          key +
          ": " +
          value +
          " from " +
          field +
          ". Continue?"
      );

      if (confirm) {
        this.$store
          .dispatch("settings/deleteCustomerPriceGroup", {
            key: key,
            field: field,
          })
          .then(() => {});
      }
    },
    savePriceGroup: function () {
      if (
        this.customerPriceGroupId === "" ||
        this.customerPriceGroupName === ""
      ) {
        return false;
      }

      this.$store
        .dispatch("settings/updateCustomerPriceGroup", {
          key: this.customerPriceGroupId,
          value: this.customerPriceGroupName,
          field: this.customerPriceGroupField,
        })
        .then(() => {
          this.closeCustomerPriceGroup();
        });
    },
    saveRole: function () {
      var oldName = this.selectedRole.name;
      var newName = this.newRoleName;

      if (oldName === newName) {
        this.exitRoleEdit();
      } else {
        this.$store
          .dispatch("settings/updateUserRoleName", {
            role: this.selectedRole.slug,
            name: newName,
          })
          .then(() => {
            this.exitRoleEdit();
          });
      }
    },
    exitRoleEdit: function () {
      this.selectedRole = false;
      this.newRoleName = "";
    },
    initRoleEdit: function (role) {
      this.selectedRole = role;

      this.$nextTick(() => {
        this.$refs["roleName"].focus();
      });
    },
    initOptionEdit: function (option) {
      this.selectedOption = option;

      this.$nextTick(() => {
        this.$refs["optionField"].focus();
      });
    },
    exitOptionEdit: function () {
      this.selectedOption = false;
      this.optionField = "";
    },
    saveOption: function () {
      var oldValue = this.appSettings[this.selectedOption];
      var newValue = this.optionField;

      console.log(oldValue);
      console.log(newValue);

      if (oldValue === newValue) {
        this.exitOptionEdit();
      } else {
        this.$store
          .dispatch("settings/updateAppSettingsOption", {
            field: this.selectedOption,
            value: newValue,
          })
          .then(() => {
            this.exitOptionEdit();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none !important;
}
</style>
